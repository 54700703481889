
import React from 'react';

import { useLocation } from 'react-router-dom';
import { dataPost } from '../common/data';
import useWindowDimensions from '../hooks/useWIndowDimensions';

export default function Posts() {
    const { height } = useWindowDimensions();
    const location = useLocation();

    return (dataPost.map(item => ('/news/' + item.slug) === location.pathname &&
        <main key={item.id} className='px-4 container text-base lg:text-lg my-8 lg:my-16 text-justify'>
            <h1 className='title uppercase text-blue700 mb-6'>
                {item.title}
            </h1>
            {item.posts.map((item, idx) =>
                <div key={idx}>
                    <h1 className='text-base md:text-lg lg:text-xl font-semibold mt-4 mb-2'>{item.title1}</h1>
                    <i className='font-medium text-sm md:text-base'>{item.title2}</i>
                    {item?.video && <div className="aspect-w-16 aspect-h-9 rounded-lg w-full">
                        <iframe src={item.video} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>  </iframe>
                    </div>
                    }
                    <div>
                        <p>{item.content}</p>
                    </div>
                    <ul className='list-disc ml-5'>
                        {item?.list?.map(el =>
                            <li>{el}</li>
                        )}
                    </ul>

                    {item?.images &&
                        <img src={item.images} alt='' style={{ maxHeight: height }} className='py-3 mx-auto rounded-lg' />
                    }
                </div>
            )}
        </main>)
    )
}
