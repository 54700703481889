
import { Dialog } from '@headlessui/react';
import { Globe, List, MagnifyingGlass, ShoppingCart, X } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/Logo.svg';
import './style.css';


export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    return (
        <nav className="shadow bg-white z-10">
            <div className='container px-4  flex items-center justify-between py-4 '>
                <div className="flex justify-between pr-2 pb-0">
                    <div className="flex lg:hidden mr-6">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <List size={24} />
                        </button>
                    </div>
                    <div className="flex items-center flex-shrink-0 text-gray-800 mr-0 lg:mr-8">
                        <Link to='/'>
                            <img src={logo} alt='logoTMD' className='w-24 md:w-32' />
                        </Link>
                    </div>
                    <div className="text-lg font-medium uppercase flex-grow hidden lg:block">
                        <Link to={"/introduction"} className="mt-4 inline-block lg:mt-0 hover:text-blue700 lg:px-4 py-2 rounded mr-2 focus:text-blue700 ">
                            GIỚI THIỆU
                        </Link>
                        <Link to={"/product"} className="mt-4 inline-block lg:mt-0  hover:text-blue700 px-0 lg:px-4 py-2 rounded mr-2 focus:text-blue700">
                            SẢN PHẨM
                        </Link>
                        <Link to={"/project"} className="mt-4 inline-block lg:mt-0  hover:text-blue700 px-0 lg:px-4 py-2 rounded mr-2 focus:text-blue700">
                            DỰ ÁN
                        </Link>
                        <Link to={"/news"} className="mt-4 inline-block lg:mt-0  hover:text-blue700 px-0 lg:px-4 py-2 rounded mr-2 focus:text-blue700">
                            TIN TỨC
                        </Link>
                        <Link to={"/contact"} className="mt-4 inline-block lg:mt-0  hover:text-blue700 px-0 lg:px-4 py-2 rounded mr-2 focus:text-blue700">
                            LIÊN HỆ
                        </Link>
                    </div>
                </div>

                <div className="flex items-center w-auto">
                    <div className="relative mx-auto text-gray-600 block">
                        <button type="submit" className="lg:hidden block text-md py-2 rounded text-blue700 ml-2 font-bold mt-0">
                            <MagnifyingGlass size={24} className='text-blue700' />
                        </button>
                        <button type="submit" className="absolute left-0 top-0 mt-3 ml-2 hidden lg:block">
                            <MagnifyingGlass size={16} />
                        </button>
                        <input
                            className="border hidden lg:block border-grey60 bg-grey20 h-10 pl-7 pr-2 rounded-lg text-lg focus:outline-none"
                            type="search" name="search" placeholder="Tìm kiếm sản phẩm" />
                    </div>
                    <div className="flex ">
                        <a href="#"
                            className="block text-md px-2 md:px-5 py-2 rounded text-blue700 md:ml-2 font-bold mt-0">
                            <ShoppingCart size={24} />
                        </a>

                        {/* <div className="dropdown lg:inline-block relative hidden">
                            <div
                                className="flex flex-row text-md  py-2 rounded text-blue700 font-bold mt-4 lg:mt-0 cursor-pointer">
                                <Globe size={24} />
                                <span className='ml-1'>VN</span>
                            </div>
                            <ul className="dropdown-menu absolute hidden text-blue700 pt-1">
                                <li className="">
                                    <a className="rounded bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">EN</a></li>
                            </ul>
                        </div> */}

                    </div>
                </div>
            </div>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                {/* <div className="fixed inset-0 z-10" /> */}
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <Dialog.Panel className="shadow-xl w-2/3 md:w-1/2 fixed inset-y-0 left-0 z-10 overflow-y-auto bg-white px-10 py-10 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <X className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <Link to="/"
                                    onClick={() => setMobileMenuOpen(false)}
                                    className="-mx-3 border-grey100 border-b  block px-3 py-5 text-base font-medium leading-7 text-gray-900 focus:text-blue700 hover:bg-gray-50"
                                >
                                    TRANG CHỦ
                                </Link>
                                <Link to="/introduction"
                                    onClick={() => setMobileMenuOpen(false)}
                                    className="-mx-3 border-grey100 border-b  block px-3 py-5 text-base font-medium leading-7 text-gray-900 focus:text-blue700 hover:bg-gray-50"
                                >
                                    GIỚI THIỆU
                                </Link>
                                <Link to="/product"
                                    onClick={() => setMobileMenuOpen(false)}
                                    className="-mx-3 border-grey100 border-b  block px-3 py-5 text-base font-medium leading-7 text-gray-900 focus:text-blue700 hover:bg-gray-50"
                                >
                                    SẢN PHẨM
                                </Link>
                                <Link to="/project"
                                    onClick={() => setMobileMenuOpen(false)}
                                    className="-mx-3 border-grey100 border-b  block px-3 py-5 text-base font-medium leading-7 text-gray-900 focus:text-blue700 hover:bg-gray-50"
                                >
                                    DỰ ÁN
                                </Link>
                                <Link to="/news"
                                    onClick={() => setMobileMenuOpen(false)}
                                    className="-mx-3 border-grey100 border-b  block px-3 py-5 text-base font-medium leading-7 text-gray-900 focus:text-blue700 hover:bg-gray-50"
                                >
                                    TIN TỨC
                                </Link>
                                <Link to="/contact"
                                    onClick={() => setMobileMenuOpen(false)}
                                    className="-mx-3 border-grey100 border-b  block px-3 py-5 text-base font-medium leading-7 text-gray-900 focus:text-blue700 hover:bg-gray-50"
                                >
                                    LIÊN HỆ
                                </Link>
                            </div>
                            {/* <div className='flex flex-row justify-between  mt-4 py-2'>
                                <p>Ngôn ngữ: </p>
                                <div className="relative dropdown ">
                                    <div
                                        className="flex flex-row text-md rounded text-blue700 font-bold lg:mt-0 cursor-pointer">
                                        <Globe size={24} />
                                        <span className='ml-1'>VN</span>
                                    </div>
                                    <ul className="dropdown-menu absolute hidden text-blue700 pt-1">
                                        <li className="">
                                            <a className="rounded bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">EN</a></li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </nav>
    )
}
