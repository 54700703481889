import { images } from "../constant/GetImages";

export const dataPost = [
    {
        id: 1,
        title: 'TMD CHÍNH THỨC TRỞ THÀNH NHÀ PHÂN PHỐI LOA THÔNG MINH OLLI MAIKA TRÊN TOÀN QUỐC',
        avatar: images.Post1Picture1,
        posts: [
            {
                content: 'Ngày 17/03/2023, buổi lễ ký kết hợp tác trở thành nhà phân phối trên thị trường toàn quốc giữa Công ty Cổ phần Công nghệ OLLI (OLLI Technology) và Công ty TNHH Ứng dụng và phát triển Công nghệ TMD (TMD) đã được diễn ra tại văn phòng của OLLI - 96 NTMK, Phường 6, Quận 3, TP.Hồ Chí Minh.',
                images: images.Post1Picture1
            },
            {
                content: 'Công ty TNHH TMD được thành lập năm 2021 với nhiệm vụ chuyên cung cấp các thiết bị ngành cơ điện lạnh và mang đến giá trị gia tăng cho Khách hàng, đồng thời nâng cao khả năng phục vụ Khách Hàng bằng những sản phẩm, dịch vụ và công nghệ điện lạnh tiên tiến trên thế giới. Hiện tại, TMD rất tự hào khi đã trở thành nhà phân phối chính thức của các hãng sản xuất thiết bị ngành cơ điện lạnh và công nghệ điện tử nổi tiếng về chất lượng tại Việt Nam và trên thế giới như Cáp điện Z43 - Bộ Quốc Phòng Việt Nam, Cáp điện Benka - Thổ Nhĩ Kỳ,... '
            },
            {
                content: 'Sự hợp tác giữa OLLI và TMD đã đánh dấu một bước ngoặt lớn trong quá trình phát triển của hai bên, góp phần đưa loa thông minh tích hợp trợ lý ảo MAIKA và các sản phẩm trong hệ sinh thái nhà thông minh như thiết bị ngành cơ điện lạnh đến gần hơn với gia đình Việt trên khắp cả nước, đồng thời mang đến cho người dùng trải nghiệm chất lượng và tiện nghi nhất.'
            },
            {
                content: 'Tham gia buổi lễ ký kết có đại diện của Công ty OLLI Technology là Ông Bùi Bách Việt – chức vụ Phó Tổng Giám đốc; về phía Công ty TNHH TMD có Ông Phan Hùng Minh – chức vụ Giám đốc.',
                images: images.Post1Picture2
            },
            {
                content: 'Phát biểu tại lễ ký kết, Ông Bùi Bách Việt - Phó Tổng Giám đốc của OLLI Technology cho biết: "Chúng tôi đánh giá cao các thành tựu trong lĩnh vực phân phối thiết bị cơ điện lành và công nghệ điện tử mà TMD đã đạt được chỉ trong hơn 1 năm sau khi thành lập. Chúng tôi tin tưởng rằng TMD sẽ phát huy được thế mạnh của cả hai bên và cùng hướng đến một mục tiêu chung là phát triển thị trường loa thông minh và các sản phẩm smarthome trên toàn quốc". '
            },
            {
                content: 'Ông Phan Hùng Minh - Giám đốc của TMD chia sẻ tâm huyết: "Mục tiêu của chúng tôi là cung cấp và phân phối ngành cơ điện lạnh tối ưu nhằm phục vụ cho ngành công nghiệp và dân dụng. Bên cạnh đó, chúng tôi cũng tạo được liên kết bền vững với đơn vị thi công M&E chuyên nghiệp nhằm phục vụ cho các công trình trên toàn quốc. Với tinh thần đó, chúng tôi xin cam kết đem lại cho OLLI và Quý Khách hàng sự Uy tín, Chất lượng, Chuyên nghiệp và hiệu quả. Chúng tôi tin rằng với sự hợp tác này, TMD tin rằng OLLI sẽ ngày một tiến xa hơn trên con đường mang sản phẩm trợ lý ảo phục vụ cuộc sống cho hàng triệu gia đình Việt".'
            },
            {
                content: 'Sự kiện này đánh dấu một bước ngoặt quan trọng với sự kỳ vọng đôi bên sẽ đạt được các mục tiêu phát triển mạnh mẽ trong thời gian tới. Việc thoả thuận hợp tác nhà phân phối cùng TMD cũng là dấu mốc quan trọng của OLLI Technology trong việc đưa sản phẩm công nghệ trí tuệ nhân tạo “made in Vietnam” đến gần hơn với đông đảo người dùng Việt trên toàn quốc. '
            }
        ],
        slug: 'TMD-CHINH-THUC-TRO-THANH-NHA-PHAN-PHOI-LOA-THONG-MINH-OLLI-MAIKA-TREN-TOAN-QUOC'
    },
    {
        id: 2,
        title: 'Thử nghiệm các tín năng nổi bật của loa OLLI MAIKA',
        slug: 'thu-nghiem-cac-tinh-nang-noi-bat-cua-loa-olli-',
        posts: [
            {
                title1: "Tính năng giải trí",
                content: 'Trong quý 1 năm 2023 vừa qua , Công ty TNHH TMD đã phân phối nhiều lô hàng cho các nhà máy sản xuất tủ điện lớn như Hải Nam , Nam Hoà và Duy Hải',
                images: images.Post2Picture1
            },
            {
                title2: "Nghe nhạc",
                list: [
                    'Maika ơi, bật nhạc của Mỹ Tâm',
                    'Maika ơi, phát nhạc gì vui vui đi',
                    'Maika ơi, mở danh sách nhạc yêu thích',
                    'Maika ơi, bài hát này tên gì vậy'
                ],
            },
            {
                title2: "Nghe Radio",
                list: [
                    'Maika ơi, bật đài đi',
                    'Maika ơi, mở radio',
                    'Maika ơi, mở kênh FM 99.9 MHz',
                ],
            },
            {
                title2: "Nghe podcast",
                list: [
                    'Maika ơi, mở podcast',
                    'Maika ơi, bật đàm thoại',
                    'Tôi muốn nghe podcast',
                    'Mở chương trình Đọc truyện đêm khuya',
                ],
            },
            {
                title2: "Maika ơi, kể truyện cổ tích",
                list: [
                    'Maika ơi, kể truyện cổ tích',
                    'Maika ơi, bật truyện Cô bé quàng khăn đỏ',
                    'Maika ơi, bật truyện ngụ ngôn',
                    'Maika ơi, mở truyện Thỏ và Rùa',
                    'Maika ơi, mở truyện trước đó'
                ],
            },
            {
                title2: "Kể truyện cười",
                list: [
                    'Maika ơi, kể chuyện cười đi',
                    'Maika ơi, phát chuyện cười khác',
                ],
            },
            {
                title2: "Truyện kinh dị",
                list: [
                    'Maika ơi, tôi muốn nghe truyện kinh dị',
                    'Maika ơi, kể chuyện ma đi',
                ],
            },
            {
                title2: "Pháp thoại",
                list: [
                    'Maika ơi, mở pháp thoại',
                    'Maika ơi, mở pháp thoại của thầy Thích Minh Niệm',
                    'Maika ơi, tắt pháp thoại',
                    'Maika ơi, mở lại pháp thoại',
                ],
            },
            {
                title2: "Thơ ca",
                list: [
                    'Maika ơi, đọc thơ đi',
                    'Maika ơi, đọc thơ tình',
                    'Maika ơi, đọc truyện Kiều',
                    'Maika ơi, bài thơ tiếp theo/Mở phần tiếp theo',
                    'Maika ơi, bài thơ trước đó/Mở phần trước đó',
                    'Maika ơi, tắt đi'
                ],
            },
            {
                title2: "Đố vui",
                list: [
                    'Maika ơi, đố vui đi',
                    'Maika ơi, nhắc lại câu hỏi',
                    'Maika ơi, tạm dừng đố vui',
                    'Maika ơi, tắt đi'
                ],
                images: images.Post2Picture2
            },
            {
                title1: 'Tính năng nhà thông minh',
                content: 'Mọi thành viên trong gia đình bạn đều có thể dễ dàng điều khiển nhà thông minh với loa thông minh OLLI MAIKA chỉ bằng một câu nói. Không những điều khiển từng thiết bị riêng lẻ, OLLI MAIKA còn giúp bạn có những trải nghiệm tuyệt vời trong chính ngôi nhà của mình bằng cách thiết lập nhiều ngữ cảnh khác nhau chỉ với một câu lệnh duy nhất. Đặc biệt, bạn sẽ không còn lo lắng việc quên tắt các thiết bị khi ra khỏi nhà.',
            },
            {
                title: 'Tính năng trợ lý',
                content: 'Bạn có quá nhiều việc phải làm trong ngày và cần một trợ lý ghi nhớ, nhắc nhở, sắp xếp danh sách công việc cũng như giải đáp những thắc mắc? Bạn cần kết nối với gia đình, bạn bè dễ dàng, nhanh chóng, miễn phí? OLLI MAIKA chính là trợ lý cá nhân tuyệt vời giúp bạn thực hiện những công việc đó!',
            },
            {
                title: 'Tính năng thông tin',
                content: 'Mỗi ngày trôi qua có muôn vàn thông tin mới, OLLI MAIKA sẽ giúp bạn cập nhật nhanh chóng những nội dung hữu ích theo yêu cầu.',
            },
            {
                title: 'Tính năng tiện ích',
                content: 'Ngoài ra, OLLI MAIKA có thể hỗ trợ bạn với đa dạng các tiện ích thường ngày khác.',
            },
        ],
        avatar: images.Post2Picture1
    },
    {
        id: 3,
        title: 'Hướng dẫn cách chọn dây điện, cáp điện phù hợp nhất',
        slug: 'huong-dan-cach-chon-day-dien',
        avatar: images.Post3Picture1,
        posts: [
            {
                content: 'Việc lựa chọn dây dẫn với tiết diện có phù hợp với dòng điện hay không ảnh hưởng rất lớn đến hiệu quả, độ an toàn cũng như tính tiết tiết kiệm điện năng cho căn nhà của bạn. Do đó, chúng tôi xin cung cấp cho bạn đọc một số thông tin cần thiết cũng như bảng chọn tiết diện dây dẫn theo dòng điện để người đọc có thể trang bị thêm cho mình kiến thức về vấn đề này.',
                images: images.Post3Picture1
            },
            {
                title2: 'Khái quát về cách chọn dây điện, cáp điện',
                content: 'Lựa chọn tiết diện dây điện và cáp điện là công việc quan trọng và thường xuyên đối với ngành điện. Tuy có nhiều cách chọn khác nhau nhưng thường thì ta sẽ có 3 phương pháp chính (Chọn dây, cáp điện theo tính toán, Chọn dây, cáp điện theo kinh nghiệm, Chọn dây, cáp điện theo các tiêu chuẩn)',
                list: [
                    'Chọn dây điện, cáp điện theo tính toán ',
                    'Chọn dây, cáp điện theo các tiêu chuẩn',
                    'Chọn dây, cáp điện theo kinh nghiệm'
                ]
            },
            {
                title2: 'Chọn dây điện, cáp điện theo tính toán',
                content: 'Để chinh phục thị trường bằng chất lượng sản phẩm tương đương ngoại nhập, chúng tôi chủ động tập trung đầu tư vào các công nghệ mới nhất, thiết bị hiện đại nhất và hệ thống quản lý chất lượng ISO 9001:2015 với nguồn vật tư, nguyên liệu đạt chuẩn được nhập về từ nước ngoài với hàm lượng tinh chất đồng đạt 99,99%, nhôm đạt 99,7% và nhựa PVC, PE, LSZH, XLPE, XLPO,… không độc hại, đạt tiêu chuẩn an toàn của EU. Khả năng và thiết bị hiện đại của chúng tôi cũng đảm bảo rằng chất lượng sản phẩm được duy trì tối đa trong suốt quá trình hoàn thiện, từ khâu kiểm tra nguyên liệu thô đến thành phẩm.',
            },
            {
                content: 'Không chỉ chú trọng tới chất lượng, nhà máy Z143 còn đầu tư về mẫu mã, kiểu dáng và cung cấp mức giá cả cạnh tranh cho mọi khách hàng. Với mục tiêu trở thành doanh nghiệp hàng đầu trong lĩnh vực sản xuất dây và cáp thông tin, dây và cáp điện, Nhà máy Z143 luôn đảm bảo uy tín của doanh nghiệp, chất lượng của sản phẩm, hiệu quả công việc và đáp ứng mọi nhu cầu của khách hàng. Chúng tôi luôn không ngừng nỗ lực cải tiến, đa dạng hóa các sản phẩm của mình, nhằm đem lại cho khách hàng những sản phẩm, dịch vụ tốt nhất.'
            },
            {
                title2: 'Chọn dây điện, cáp điện theo kinh nghiệm',
                content: 'Để lựa chọn dây, cáp điện sao cho chính xác và phù hợp nhất với ngôi nhà của mình, bạn phải có những kiến thức và kinh nghiệm nhất định về những bước sau đây:',
                list: [
                    'Xác định nguồn điện sẽ dùng: Để biết được nguồn điện mình nên dùng trong tương lai sắp tới, người dùng buộc phải dựa trên thiết bị điện trong nhà mà họ sẽ dùng là thiết bị 1 pha hay 3 pha, đồng thời cũng phải dựa vào nguồn cung cấp của điện lực tại nơi mình ở có những loại nguồn điện nào. Hiện nay, phần lớn nguồn điện dùng cho hộ gia đình ở Việt Nam là nguồn 1 pha 2 dây.',
                    'Tính tổng công suất thiết bị tiêu thụ điện: Các thiết bị tiêu thụ điện trong nhà ở là những thiết bị tiêu tốn năng lượng điện như: đèn, quạt, nồi cơm điện, tủ lạnh, máy giặt, lò vi sóng, điều hòa nhiệt độ, máy bơm nước…',
                ]
            },
            {
                content: 'Trên các thiết bị tiêu thụ điện, đều có ghi trị số công suất, có đơn vị là W (Woat) hoặc kW (Kilô-Woat) hoặc HP (Horse Power- Sức ngựa). Một cách gần đúng, có thể xem tất cả các trị số công suất ghi trên các thiết bị là công suất tiêu thụ điện. Vì vậy, tổng công suất thiết bị tiêu thụ điện là tổng của tất cả trị số công suất của các thiết bị tiêu thụ điện trong nhà.'
            },
            {
                content: 'Khi gặp các đơn vị công suất khác nhau thì quy đổi sang cùng một đơn vị như sau: (1kW = 1.000W, 1HP = 750W)'
            },
            {
                content: 'Lựa chọn dây dẫn cho từng phần của nhà ở: Tùy theo công suất chịu tải của từng nhánh trong sơ đồ điện, người dùng có thể chọn nhiều loại dây, cỡ dây khác nhau cho từng phần nhà ở của mình.'
            },
            {
                content: 'Lựa chọn dây dẫn cho từng phần của nhà ở, bao gồm ba bước nhỏ: ',
                list: [
                    'Lựa chọn đọan dây ngoài trời',
                    'Lựa chọn đọan cáp điện kế',
                    'Lựa chọn dây cho từng nhánh và dây đến từng thiết bị tiêu thụ điện.'
                ],
                images: images.Post3Picture2
            },
            {
                title1: 'Chọn dây, cáp điện theo các tiêu chuẩn',
                content: 'Một trong những cách chọn tiết diện dây dẫn được dùng phổ biến nhất hiện nay chính là dựa vào bảng chọn tiết diện dây dẫn theo dòng điện. Việc chọn theo bảng chọn tiết diện dây dẫn theo dòng điện sẽ giúp cho việc thiết kế, thi công công trình dễ dàng hơn rất nhiều cũng như phù hợp các tiêu chuẩn đã có sẵn – tiêu chuẩn IEC 60439.',
            },
            {
                content: 'Nhà máy dây cáp điện Z143 trực thuộc Bộ Quốc Phòng chuyên cung cấp các loại dây và cáp thông tin, cáp đồng trục cao tần, dây và cáp điện, phục vụ cho Quân đội và dân sinh. Chúng tôi cam kết sẽ đem lại cho khách hàng những sản phẩm thương hiệu  đảm bảo chất lượng, uy tín trên thị trường. Các dịch vụ bán hàng và sau bán hàng được phục vụ chu đáo, nhiệt tình, chuyên nghiệp. Đồng thời, chia sẻ chính sách giá cả hợp lý, chiết khấu bán hàng hấp dẫn, phù hợp, tiết kiệm… Hãy liên hệ ngay với chúng tôi để mua được những sản phảm chất lượng và mức giá cạnh tranh nhất.',
                images: images.Post3Picture3
            }
        ]
    },
    {
        id: 4,
        title: 'Những Nguy Cơ Tiềm Ẩn Khi Sử Dụng Dây Cáp Điện Kém Chất Lượng',
        slug: 'nguy-hiem-tiem-an-khi-su-dung-day-cap-dien-kem-chat-luong',
        avatar: images.Post4Picture1,
        posts: [
            {
                content: 'Dây cáp điện là sản phẩm phục vụ cho ngành công nghiệp hiện đại. Nó có vai trò vô cùng quan trọng trong đời sống cũng như trong hoạt động sản xuất, kinh doanh. Trên thị trường hiện có nhiều sản phẩm dây cáp điện kém chất lượng nhưng được gắn mẫu mã các thương hiệu nổi tiếng. Vì thế, nhiều người tiêu dùng dễ dàng mua phải những sản phẩm dây cáp điện kém chất lượng, không đạt tiêu chuẩn an toàn. Hãy cùng nhà máy Z143 tìm hiểu về những tác hại khi sử dụng dây cáp điện kém và cách lựa chọn dây cáp chất lượng cao trong bài viết sau nhé.',
                images: images.Post4Picture1
            },
            {
                title2: 'Tìm hiểu về dây cáp điện kém chất lượng',
                content: 'Những loại dây cáp điện kém chất lượng trên thị trường hiện nay thường được làm từ kim loại đồng có nhiều tạp chất. Đường kính các sợi nhỏ, thậm chí thiếu 1 số sợi, vì thế, tiết diện của ruột dẫn sẽ nhỏ hơn so với quy định. Điều này khiến cho điện trở tăng và gây tiêu hao điện năng sử dụng nhiều hơn. Không những thế, khả năng chịu cường độ dòng điện kém do tiết diện và điện trở lớn gây quá tải và sinh nhiệt khi sử dụng, gây ra nguy cơ cháy nổ cao.',
            },
            {
                content: 'Dây cáp điện giả có lớp cách điện được làm từ nhựa tái chế nên xốp, bở, giòn, và dễ gãy nứt khi uốn, lắp đặt. Vì thế, trong quá trình sử dụng, dây cáp điện kém chất lượng sẽ dễ bị nóng lên, rò điện, chập cháy. Sử dụng những loại dây này sẽ gây nguy cơ thiệt hại về vật chất và nguy hiểm tới tính mạng con người.',
                images: images.Post4Picture2
            },
            {
                title1: 'Những tác hại khi sử dụng dây, cáp điện kém chất lượng',
                content: 'Khi dây điện có ruột đồng/ nhôm kém chất lượng hoặc tiết diện không đủ có thể gây ra những tác hại lớn. Dưới đây là một số hậu quả khi sử dụng những loại dây cáp điện kém chất lượng:',
                list: [
                    'Các thiết bị điện hoạt động kém hiệu quả do dây điện kém, gây sụt giảm áp trên đường dây. Khiến cho tuổi thọ của thiết bị này giảm nhanh.',
                    'Trong trường hợp dây có nhiệt độ cao, lớp cách điện có thể bị chảy, gây chạm chập, và cháy nổ.',
                    'Ruột đồng/nhôm có chất lượng kém dễ bị gãy, khó nối, khó lắp vào các phụ kiện điện khác.'
                ]
            },
            {
                content: 'Nếu dây cáp điện có lớp cách điện kém chất lượng thì có thể gây các ảnh hưởng xấu như sau:',
                list: [
                    'Người dùng có thể bị điện giật do bị nứt cách điện, hở ruột dẫn',
                    'Sau 1 thời gian ngắn dây có thể bị rạn nứt cách điện, gây rò điện, tổn thất điện năng. Nghiêm trọng hơn là dẫn đến tình trạng chạm chập, cháy nổ.',
                    'Lớp cách điện kém chất lượng không chịu được nhiệt độ cho phép của ruột dẫn, chảy nhão gây chạm chập, cháy nổ.',
                    'Khi cháy bởi những tác nhân bên ngoài, dây không tự tắt được.',
                    'Dây cáp điện sẽ bị mất màu nhanh chóng sau 1 thời gian ngắn, gây nhầm lẫn các dây với nhau khi thay thế, sửa chữa.'
                ]
            },
            {
                title1: 'Kinh nghiệm chọn dây và cáp điện uy tín từ Nhà máy Z143',
                content: 'Với nhiều loại dây điện trên thị trường hiện nay, có tốt, có xấu, có thật, có giả. Hay thậm chí còn có những loại dây không có nhãn mác, nhà sản xuất gì cả. Vì thế, với 1 người không chuyên thì việc lựa chọn dây nào, mua ở đâu uy tín không phải là điều dễ dàng. Sau đây, Nhà máy Z143 – Bộ Quốc Phòng sẽ chia sẻ tới các bạn 1 vài kinh nghiệm để chọn dây được tốt nhất.',
                list: [
                    'Bạn không nên chọn dây không có nhãn mác trên bao bì, không có tên nhà sản xuất, không có địa chỉ rõ ràng. Hãy tham khảo những sản phẩm đảm bảo chất lượng tại Z43 Company để lựa chọn được loại dây điện, cáp điện phù hợp nhất.',
                    'Không nên chọn dây không có những thông tin cơ bản như: nhãn hiệu, tên loại dây, tiết diện, cấu trúc ruột dẫn (số sợi và đường kính mỗi sợi), tiêu chuẩn sản xuất.',
                    'Dây điện tốt thường có vỏ ngoài nhựa bóng láng. Đồng thời, dây có lớp nhựa cách điện khá dẻo, khi tuốt ra khỏi ruột dẫn có thể kéo giãn gấp đôi, gấp 3 so với chiều dài ban đầu mà không bị đứt. Dây có thể bẻ gập nhiều lần hoặc xoắn gút nhưng bền mặt lại ko bị rạn nứt.. Bạn nên kiểm tra kỹ càng dây trước khi quyết định mua để chọn được loại dây chất lượng nhất.',
                    'Một cách khác là bạn có thể kiểm tra ruột dẫn bằng cách đếm số sợi nhỏ bên trong so với số sợi được ghi bên ngoài. Tuy nhiên cách này không khả quan bởi các sợi bên trong nhỏ và khó kiểm tra. Nhưng đối với những thương hiệu uy tín, ví dụ như nhà máy Z143, trên dây có ghi cụ thể cấu trúc ruột dẫn thì bạn có thể yên tâm sử dụng.',
                    'Dây dẫn tốt thì ruột của nó sáng, bóng, nếu là đồng thì ruột rất mềm dẻo. Loại có nhiều sợi nhỏ thì có thể sử dụng 2 ngón tay xoắn ruột dẫn dễ dàng mà không làm bung các sợi nhỏ, không gãy. Đối với ruột dẫn có 1 lõi dẫn thi có thể bẻ gập ruột đồng tới vài chục lần mà không bị gãy.',
                    'Những loại dây chất lượng tốt giá cao hơn những dòng hàng dỏm cùng loại. Bạn không nên ham rẻ mà hãy lựa chọn những thương hiệu uy tín để sử dụng.'
                ],
                images: images.Post4Picture3
            },
            {
                content: 'Nhà máy dây cáp điện Z43 luôn sử dụng những vật liệu đầu vào đã được tuyển chọn khắt khe, hợp tác với những nhà phân phối uy tín hàng đầu nước ta. Trải qua quy trình sản xuất khép kín, những sản phẩm tại nhà máy Z143 – Bộ Quốc Phòng đã được đảm bảo về chất lượng, đáp ứng các tiêu chuẩn trong và ngoài nước. Nếu đang phân vân không biết mua dây điện, cáp điện tại đâu để đảm bảo uy tín, an toàn thì hãy liên hệ ngay với TMD nhé.'
            }
        ]
    },
    {
        id: 5,
        title: 'Những biện pháp hạn chế cháy nổ do chập điện',
        slug: 'nhung-bien-phap-han-che-chay-no-do-chap-dien',
        avatar: images.Post5Picture1,
        posts: [
            {
                content: 'Hiện tượng chập điện là 1 sự cố rất nguy hiểm có thể xảy ra một cách bất ngờ. Nó không chỉ gây hỏng những thiết bị điện mà còn phá hủy tài sản, thậm chí liên quan tới tính mạng con người. Vì vậy, khi dùng điện luôn bắt buộc cẩn thận và cảnh giác cao. Để giúp người tiêu dùng điện mang thể giảm thiểu được các sự cố đáng tiếc. Chúng tôi xin chia sẻ 1 số nguyên nhân và giải pháp khắc phục cháy chập điện. Cháy chập điện do đâu và cách khắc phục như thế nào?',
                images: images.Post5Picture1
            },
            {
                title1: 'Một số nguyên nhân gây cháy nổ do chập điện',
                content: 'Những sự cố điện nào có thể gây cháy? Có rất nhiều sự cố nhưng phần lớn do:',
            },
            {
                title2: 'Cháy dây điện do việc sử dụng quá tải:',
                content: 'Dòng điện các phụ tải tiêu thụ điện quá lớn so với dòng điện định mức của dây dẫn từ các thiết bị đóng cắt hoặc nguồn cấp. Đây là nguyên nhân gây chập điện, cháy điện thường gặp nhiều nhất trong sinh hoạt hằng ngày.'
            },
            {
                title2: 'Cháy do chập mạch:',
                content: 'Sự cố chập mạch là hiện tượng các pha bị chập vào nhau, hoặc có thể dây pha chạm đất gây nên điện trở dây dẫn giảm. Cường độ dòng điện tăng lớn đột ngột dẫn tới cháy cách điện dây dẫn, phát sinh tia lửa điện – là nguyên nhân gây chập điện và cháy thiết bị điện.'
            },
            {
                content: 'Một số nguyên nhân gây chập mạch:',
                list: [
                    'Khi lắp đặt, khoảng cách 2 dây trần ngoài nhà không đúng tiêu chuẩn nên khi cây đổ, gió rung gây chập.',
                    'Khi 2 dây bị mất lớp vỏ bọc cách điện chập vào nhau.',
                    'Khi đấu nối đầu dây dẫn với nhau hay đấu vào máy móc thiết bị không đúng quy định.',
                    'Môi trường sản xuất có hoá chất ăn mòn dẫn tới lớp vỏ bọc cách điện bị phá huỷ'
                ]
            },
            {
                title2: 'Cháy dây điện do mối nối dây không tốt: ',
                content: 'Khi mối nối dây dẫn điện không tốt sẽ dẫn đến điện trở dây dẫn tăng lên làm cho điểm nối nóng đỏ lên và gây cháy dây dẫn cùng với các vật cháy sát bên. Khi mối nối dây dẫn lỏng hoặc hở sẽ có xuất hiện tia lửa điện, được phóng qua không khí (móc nối dây dẫn điện, đóng hoặc mở cầu dao, công tắc điện).'
            },
            {
                title2: 'Sự cố cháy dây điện do sự truyền nhiệt của vật tiêu thụ điện: ',
                content: 'Trong thời gian sử dụng thiết bị tiêu thụ điện, mọi hoạt động của thiết bị đều toả nhiệt. Nhiệt toả ra xung quanh thiết bị điện phụ thuộc vào tính chất môi trường, công suất và thời gian tiêu thụ điện. Nếu ta không kiểm soát hoàn toàn thì nguồn nhiệt này cũng có thể trở thành nguyên nhân dẫn đến cháy dây điện.'
            },
            {
                title2: 'Tự ý lắp đặt thêm các thiết bị, đồ dùng điện có công suất tiêu thụ lớn',
                content: 'Việc tự tay lắp đặt các thiết bị hoặc thiết bị điện công suất tiêu thụ lớn như máy điều hoà nhiệt độ, bình nước nóng, bếp điện, siêu điện…mà chúng ta không nhớ rằng các thiết bị này trước khi lắp đặt mạng điện chưa được tính toán đong đếm đến; do đó dẫn đến tăng công suất tiêu thụ điện gây nên quá tải, chập mạch và cháy dây điện.'
            },
            {
                title2: 'Không kiểm tra kỹ lưỡng các thiết bị điện',
                content: 'Những thiết bị điện không được kiểm tra và chăm sóc thường xuyên như quạt điện sẽ dẫn đến cản chiều quay. Điện năng của thiết bị điện không biến thành cơ năng được mà biến thành nhiệt năng. Trong khi đó bụi thấm dầu nhớt ở bên trong thiết bị và lớp vỏ nhựa bọc bên ngoài là nguyên nhân dẫn đến việc cháy nổ của thiết bị ở nhiệt độ cao.'
            },
            {
                title1: 'Hướng dẫn cách phòng chống cháy nổ do chập điện',
                title2: 'Cầu dao điện',
                list: [
                    'Mỗi gia đình phải lắp cầu dao, aptomat, hoặc rơle cắt điện nhanh ở phía sau điện kế, đầu đường dây chính trong nhà hoặc ở đầu mỗi nhánh dây phụ.',
                    'Tất cả cầu dao, cầu chì phải có nắp đậy. Khi phát hiện các chỗ dây điện bong tróc lớp cách điện phải thay sửa ngay, các mối nối quấn lại băng keo chắc chắn.',
                    'Thường xuyên kiểm tra các automat, trường hợp thấy automat nóng và phát tiếng kêu phải thay thế ngay.',
                    'Khi sửa chữa điện trong nhà phải cắt cầu dao điện, thông báo cho mọi người cùng biết để không đóng điện bất ngờ. Tốt nhất là nhờ người có chuyên môn kỹ thuật về điện để sửa chữa nhằm tránh nguy cơ tai nạn hoặc chập điện gây cháy sau khi đóng lại cầu dao.'
                ]
            },
            {
                title2: 'Các chú ý khác',
                list: [
                    'Không cắm nhiều thiết bị vào cùng 1 ổ điện để tránh quá tải dễ dẫn đến chập điện.',
                    'Khi sử dụng các thiết bị điện cầm tay như máy sấy tóc, máy massage, máy mài, máy khoan… phải mang găng tay cách điện để tránh giật nếu rò điện. Tắt nguồn khi di chuyển các dụng cụ đang cắm điện.',
                    'Và điều quan trọng không thể bỏ qua đó là bạn cần phải đọc kỹ hướng dẫn an toàn về điện của các thiết bị trong nhà tủ lạnh, tivi, máy giặt, lò vi sóng, máy bơm… Các thiết bị có vỏ bằng kim loại cần phải có dây nối đất.',
                    'Mỗi khi ra khỏi phải tắt hết các thiết bị dùng điện đang dùng, tốt nhất là cắt cầu dao tổng bởi thiết bị điện hoạt động trong thời gian dài rất dễ phát nhiệt gây cháy.',
                    'Làm thu lôi chống sét.',
                    'Khách hàng khi chọn dây dẫn điện “phải chọn mua thương hiệu dây điện có uy tín và đọc kỹ những thông số ghi trên dây điện gồm tiết diện lõi đồng, số sợi đồng, điện áp” để chọn mua được dây điện tốt, an toàn.'
                ],
                images: images.Post5Picture2
            },
            {
                title2: 'Lưu ý',
                content: 'Hãy nhớ: chọn tiết diện dây dẫn ĐỦ để dòng điện có thể đi qua. Bởi nếu chúng ta chọn dây dẫn có tiết diện nhỏ hơn dòng điện phụ tải thì sẽ dễ gây ra chập mạch, cháy nổ.'
            },
            {
                content: 'Chọn nơi mua hàng và hãng sản xuất dây điện uy tín để hạn chế chập điện, cháy nổ. Dây điện Z43 là thương hiệu lớn tại Việt Nam nổi tiếng trong sản xuất dây điện dân dụng có chất lượng tốt. Trước khi được xuất ra thị trường đều được kiểm định chất lượng từ nhà máy và được cấp chứng chỉ.'
            },
            {
                title2: 'Chứng chỉ kỹ thuật:',
                content: 'Tất cả các sản phẩm dây và cáp điện của Nhà máy Z43 đều được sản xuất và kiểm soát chất lượng nghiêm ngặt dựa trên các tiêu chuẩn về kỹ thuật và an toàn quốc gia. Sản phẩm của chúng tôi có đầy đủ chứng chỉ theo yêu cầu, đã và đang được đưa vào các công trình của Bộ quốc phòng cũng như các công trình dân dụng toàn quốc.'
            },
        ]
    },
    {
        id: 6,
        title: 'SẢN XUẤT DÂY ĐIỆN, CÁP ĐIỆN TẠI NHÀ MÁY Z143, TỔNG CỤC CNQP',
        posts: [
            {
                title: 'SẢN XUẤT DÂY ĐIỆN, CÁP ĐIỆN TẠI NHÀ MÁY Z143, TỔNG CỤC CNQP',
                video: 'https://www.youtube.com/embed/OvWXbwtXmm8'
            }
        ],
        slug: 'san-xuat-day-dien-cap-dien-tai-nha-may-Z143'
    },
]