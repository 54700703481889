import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Header from '../layouts/Header';
import Footer from '../layouts/Footer'
import HomePage from '../pages/HomePage';
import Introduction from '../pages/Introduction';
import News from '../pages/News';
import HomePageOld from '../pages/HomePageOld';
import Posts from '../pages/Post';

const Router = () => {

    return (
        <BrowserRouter>
            <Header />
            <Routes>
                {/* <Route
                    path=""
                    element={<HomePageOld />}
                /> */}
                <Route path="/">
                    <Route
                        path="introduction"
                        element={<Introduction />}
                    />
                    <Route
                        path="news"
                        element={<News />}
                    />
                    <Route
                        path="news/:title"
                        element={<Posts />}
                    />

                    <Route
                        path=""
                        element={<HomePage />}
                    />

                </Route>
            </Routes>
            <Footer />
        </BrowserRouter>
    );
};

export default Router;
