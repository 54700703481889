
import { EnvelopeSimple, MapPin, Phone } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function Footer() {
    const location = useLocation();
    const pathname = location.pathname;

    const [isFooter, setFooter] = useState(false);

    useEffect(() => {
        if (pathname === '/project' || pathname === '/product' || pathname === '/contact')
            setFooter(true)
        else setFooter(false);
    }, [pathname])

    return (
        <div className={`shadow-footer border border-t-grey20 -z-10 ${isFooter && 'absolute bottom-0'} w-full`} >
            <div className='px-4  container text-base lg:text-lg flex flex-col lg:text-left lg:grid grid-rows-1 grid-flow-col gap-4 py-8 '>
                <div className='flex text-center lg:text-left lg:row-span-3'>
                    <ul className='w-full'>
                        <li className='font-bold my-4'>Thông tin liên hệ</li>
                        <li className='flex flex-row justify-center lg:justify-start items-center text-blue700 py-1'><MapPin size={24} className='mr-3 w-8'  />270/28 Phan Đình Phùng, P.2, Quận Phú Nhuận,
                            Hồ Chí Minh</li>
                        <li className='flex flex-row justify-center lg:justify-start items-center text-blue700 py-1'><Phone size={24} className='mr-3' />097.812.3579 (Mr. Minh)</li>
                        <li className='flex flex-row justify-center lg:justify-start items-center text-blue700 py-1'>
                            <EnvelopeSimple size={24} className='mr-3' />minhphan@tmdcorp.vn</li>
                    </ul>
                </div>
                <div className='flex text-center lg:text-left row-span-3'>
                    <ul className='w-full'>
                        <li className='font-bold my-4 py-1'>Về chúng tôi</li>
                        <li className=' py-1'>Giới thiệu</li>
                        <li className=' py-1'>Sản phẩm kinh doanh</li>
                        <li className=' py-1'>Tin tức</li>
                    </ul>
                </div>
                <div className='flex text-center lg:text-left row-span-3'>
                    <ul className='w-full'>
                        <li className='font-bold my-4 py-1'>Chính sách</li>
                        <li className=' py-1'>Điều khoản dịch vụ</li>
                        <li className=' py-1'>Chính sách mua hàng</li>
                        <li className=' py-1'>Chính sách bảo hành</li>
                        <li className=' py-1'>Chính sách bảo mật</li>
                    </ul>
                </div>
                <div className='flex text-center lg:text-left row-span-3'>
                    <ul className='w-full'>
                        <li className='font-bold my-4'>Chứng nhận</li>
                    </ul>
                </div>
            </div>
            <div className="bg-blue700">
                <div className='px-8 container py-4 text-white text-sm lg:text-base font-normal flex flex-col lg:flex-row lg:justify-between justify-center items-center '>
                    <div className="uppercase">
                        CÔNG TY TNHH ỨNG DỤNG & PHÁT TRIỂN CÔNG NGHỆ TMD
                    </div>
                    <div className="py-2 lg:py-0">
                        Copyright © 2023 TMD
                    </div>
                </div>
            </div>
        </div>
    )
}
