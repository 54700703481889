
import React from 'react';
import Picture1 from '../assets/Introduction/Picture1.jpg';
import Picture2 from '../assets/Introduction/Picture2.jpg';

import useWindowDimensions from '../hooks/useWIndowDimensions'


export default function Introduction() {
    const { height } = useWindowDimensions();
    return (
        <main className='px-4 container text-base lg:text-lg my-8 lg:mt-16 lg:mb-8  text-justify'>
            <h1 className='title uppercase text-blue700 mb-6'>
                CÔNG TY TNHH ỨNG DỤNG & PHÁT TRIỂN CÔNG NGHỆ TMD
            </h1>
            {/* <div>
                <p>Tên đầy đủ: CÔNG TY TNHH ỨNG DỤNG & PHÁT TRIỂN CÔNG NGHỆ TMD</p>
                <p>Tên viết tắt: TMD TECHNOLOGY</p>
                <p>MST: 0316863041</p>
                <p>Trụ sở chính: 270/28 Phan Đình Phùng, P.2, Quận Phú Nhuận, Hồ Chí Minh</p>
                <p>Điện thoại - Fax: 0978.123.579</p>
                <p>Website: tmdcorp.vn</p>
                <p>Email: minhphan@tmdcorp.vn</p>
            </div> */}
            <div className='my-6'>
                <p>CÔNG TY TNHH ỨNG DỤNG & PHÁT TRIỂN CÔNG NGHỆ TMD (TMD TECHNOLOGY) là công ty hoạt động trong lĩnh vực phân phối các vật tư hệ thống cơ điện (MEP)  cho các công trình văn phòng, nhà xưởng, nhà công nghiệp, trung tâm thương mại, building, hạ tầng khu công nghiệp hoặc khu dân cư…</p>
            </div>

            <div className='my-6'>
                <p>Chúng tôi cung cấp các vật tư, phụ kiện trong thi công:</p>
                <p>· Dây cáp điện Z43 – Bộ Quốc Phòng</p>
                <p>· Dây cáp điện Benka</p>
                <p>· Ống luồn dây điện HDV</p>
                <p>· Hệ thống chống sét lan truyền Saltek</p>
                <p>· Cùng nhiều sản phẩm phục vụ cho thi công nghành MEP</p>
            </div>
            <img src={Picture1} alt='' style={{ maxHeight: height }} className='py-3 mx-auto rounded-lg' />
            <div className='my-3'>Đến với Công ty TNHH Ứng Dụng & Phát Triển Công Nghệ TMD, ngoài việc cung cấp các vật tư hệ thống cơ điện (MEP) chúng tôi còn tư vấn giải pháp tiết kiệm chi phí đâu tư và nâng cao hiệu quả  cho chủ đầu tư các công trình biệt thự, văn phòng, nhà xưởng, nhà công nghiệp, hạ tầng….</div>
            <div className='my-3'>Trong suốt quá trình hoạt động, với cơ cấu tổ chức khoa học, vận hành hệ thống chuyên môn đầy đủ chuyên nghiệp, hiện đại cùng với việc áp dụng các trang thiết bị tiên tiến trong lĩnh vực, cùng với đội ngũ nhân viên  nhiệt huyết, năng động, sáng taọ, trách nhiệm, Công ty TNHH Ứng Dụng & Phát Triển Công Nghệ TMD đã thành công đưa các sản phẩm vào các dự án với chất lượng cao, đem lại hiệu quả kinh tế và sự hài lòng cho quý công ty , quý khách hàng.</div>


            <img src={Picture2} alt='' style={{ maxHeight: height }} className='py-6 mx-auto w-auto rounded-lg' />

            <div className='my-3'>Công ty TNHH Ứng Dụng & Phát Triển Công Nghệ TMD luôn đặt khách hàng lên hàng đầu và cam kết mang đến cho khách hàng các sản phẩm tốt nhất ,chất lượng cũng như  đáp ứng đầy đủ các yêu cầu của khách hàng. Công ty hiểu rằng mỗi dự án là một thách thức và cần phải được tiếp cận với sự tận tâm và chuyên nghiệp, để đảm bảo thành công cho dự án và sự hài lòng của khách hàng.</div>

            <div className='my-3'>Công ty TNHH Ứng Dụng & Phát Triển Công Nghệ TMD có một quy trình làm việc chuyên nghiệp và khoa học, đảm bảo rằng các dự án được thực hiện giao hàng theo đúng tiến độ và chi phí, đồng thời đảm bảo chất lượng công trình. Quy trình này bao gồm việc tiến hành đánh giá, phân tích và đưa ra giải pháp tối ưu cho từng dự án, đảm bảo sự hiệu quả và tiết kiệm chi phí cho khách hàng. Công ty TNHH Ứng Dụng & Phát Triển Công Nghệ TMD đã đạt được nhiều thành tựu đáng kể và trở thành một địa chỉ tin cậy cho khách hàng có nhu cầu tìm kiếm các sản phẩm cho thi công hệ thống cơ điện.</div>

            {/* <div>
                <h1>CÁC SẢN PHẨM CUNG CẤP TIÊU BIỂU</h1>
                <h1>DÂY CÁP ĐIỆN Z43 BỘ QUỐC PHÒNG</h1>
                <p>Công dụng của dây, cáp điện Z43</p>
                <p>Dây, cáp điện dùng đề truyền tải điện (hoặc tín hiệu điều khiển – cáp điều khiển) hay dùng để đấu nối các thiết bị điện trong công nghiệp và dân dụng. Dây và cáp điện khác nhau như thế nào?</p>
                <p>– Dây dẫn gồm một hay vài lõi dẫn điện , có thể có hoặc không có lớp vỏ cách điện. Ta thường gọi là dây bọc hay dây trần.</p>
                <p>– Cáp gồm các lõi dẫn điện (vẫn có cáp một lõi , gọi là cáp đơn), có lớp vỏ cách điện và thêm các lớp vỏ bảo vệ nữa. Thường thì các lớp vỏ bảo vệ này nhằm tăng cường bảo vệ cáp chịu được các tác động bên ngoài như lực va chạm, nước , tia tử ngoại trong ánh sáng mặt trời. </p>
                <p>Cấu tạo dây, cáp điện</p>
                <p>· Ruột dẫn điện: Đồng (copper: Cu) hoặc nhôm (aluminum: Al) </p>
                <p>· Lớp cách điện: PVC hoặc XLPE </p>
                <p>· Chất độn: sợi PP (Polypropylen) </p>
                <p>· Băng quấn: băng không dệt </p>
                <p>· Lớp vỏ bọc trong: PVC hoặc PE </p>
                <p>· Giáp kim loại bảo vệ: DATA, DSTA, SWA… </p>
                <p>· Lớp vỏ bọc ngoài: PCV, PE hoặc HPPE… </p>
                <p>Phân loại</p>
                <p>+ Phân loại theo kết cấu ruột dẫn: </p>
                <p>· Dây điện dân dụng ruột dẫn cứng (một sợi cứng hoặc 7 sợi bện lại) </p>
                <p>· Dây điện dân dụng ruột dẫn mềm (nhiều sợi mềm bện lại với nhau) </p>
                <p>+ Phân loại theo số ruột dẫn điện:</p>
                <p>· Dây đơn: Cu/PVC 1x….mm2</p>
                <p>· Dây đôi: Cu/PVC/PVC 2x….mm2 </p>
                <p>· Dây ba ruột dẫn: Cu/PVC/PVC 3x….mm2 </p>
                <p>+ Phân loại theo hình dạng vỏ bọc:</p>
                <p>· Dây dân dụng bọc tròn </p>
                <p>· Dây dân dụng dạng oval </p>
                <p>· Dây dân dụng bọc dính cách (dây sup)…</p>
                <p>Các ký hiệu nhận biết</p>
                <p>– Cu: ruột dẫn điện bằng đồng </p>
                <p>– Al: ruột dẫn điện bằng nhôm </p>
                <p>– PVC: chất cách điện (vỏ bọc) bằng nhựa PVC </p>
                <p>– XLPE: chất cách điện (vỏ bọc – chỉ dùng cho cáp vặn xoắn) bằng XLPE – ATA, DATA: lớp bảo vệ bằng băng nhôm (thường dùng trong cáp Muller)</p>
                <p>– STA, DSTA: lớp bảo vệ bằng băng thép (thường dùng trong cáp chôn ngầm) </p>
                <p>– SWA: lớp bảo vệ bằng sợi thép (thường dùng trong cáp chôn ngầm)</p>
                <p>– CV, CVV: Dây cáp ruột đồng, cách điện bằng PVC, bọc vỏ bằng PVC CXV: Dây cáp ruột đồng, cách điện bằng XLPE, bọc vỏ bằng PVC </p>
                <p>– CVV(CXV)/DSTA(SWA): cáp ngầm ruột đồng, cách điện bằng PVC (XLPE), giáp bảo vệ bằng băng thép (sợi thép), bọc vỏ bằng PVC
                </p>
            </div> */}
        </main>
    )
}
