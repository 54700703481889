
import React from 'react';

import Carousel from '../components/Slider';
import { images } from '../constant/GetImages';
import { dataPost } from '../common/data';

const dataProduct = [
    {
        image: images.Product1,
        productName: 'Dây đơn cứng ',
        category: 'Dây điện dân dụng '
    },
    {
        image: images.Product2,
        productName: 'Cáp điện hạ thế ',
        category: 'Cáp điện hạ thế '
    },
    {
        image: images.Product3,
        productName: 'Cáp chậm cháy, chống cháy Z43',
        category: 'Cáp chậm cháy, chống cháy Z43'
    },
    {
        image: images.Product1,
        productName: 'Dây đơn mềm',
        category: 'Dây điện dân dụng '
    },
    {
        image: images.Product5,
        productName: 'Loa thông minh OLLI MAIKA (màu đen)',
        category: 'Loa thông minh OLLI MAIKA'
    },
    {
        image: images.Product6,
        productName: 'Loa thông minh OLLI MAIKA (màu xám trắng)',
        category: 'Loa thông minh OLLI MAIKA'
    },
    {
        image: images.Product7,
        productName: 'Loa thông minh OLLI MAIKA (màu hồng)',
        category: 'Loa thông minh OLLI MAIKA'
    },
    {
        image: images.Product8,
        productName: 'Van cửa Inox',
        category: 'Van cửa'
    },
    {
        image: images.Product9,
        productName: 'Chụp thông gió Inox',
        category: 'Chụp thông gió'
    },
    {
        image: images.Product10,
        productName: 'Phễu thoát sàn',
        category: 'Phễu thoát sàn'
    },
    {
        image: images.Product11,
        productName: 'Máy lọc nước Famor HYDROGEN – FM79i+',
        category: 'Máy lọc nước'
    },
    {
        image: images.Product12,
        productName: 'Máy lọc nước Famor 79Plus',
        category: 'Máy lọc nước'
    },
    {
        image: images.Product13,
        productName: 'Máy lọc nước Famor F68',
        category: 'Máy lọc nước'
    },
    {
        image: images.Product14,
        productName: 'Máy lọc nước nóng lạnh bán công nghiệp Famor',
        category: 'Máy lọc nước'
    },
    {
        image: images.Product15,
        productName: 'Máy lọc nước công nghiệp Famor',
        category: 'Máy lọc nước'
    },
    {
        image: images.Product16,
        productName: 'Công tắc / Ổ cắm Jung',
        category: 'Công tắc / Ổ cắm'
    },
    {
        image: images.Product17,
        productName: 'Vòi nước Borras',
        category: 'Vòi nước'
    },
]

const arrPartner = [
    images.Partner1, images.Partner2, images.Partner3, images.Partner4, images.Partner5, images.Partner6, images.Partner7, images.Partner8, images.Partner9, images.Partner10
];

export default function HomePage() {

    return (
        <main>
            <div className='mx-0 md:mx-0 py-8 md:py-14'>
                <div className='px-4 container flex flex-col lg:flex-row justify-center md:justify-between items-start h-auto lg:h-[420px]'>
                    <img src={images.NewsPicture1} alt='' className='w-full lg:w-1/2 rounded' />
                    <div className='lg:px-8 text-left h-full text-ellipsis overflow-hidden '>
                        <h1 className='title text-blue700 uppercase my-4 lg:mb-6 lg:my-0 text-justify'>CÔNG TY TNHH ỨNG DỤNG & PHÁT TRIỂN CÔNG NGHỆ TMD</h1>
                        <p className='text-lg text-ellipsis overflow-hidden text-justify'>
                            Công Ty TNHH Ứng Dụng & Phát Triển Công Nghệ TMD được thành lập năm 2021 với nhiệm vụ chuyên cung cấp các thiết bị ngành cơ điện lạnh và mang đến giá trị gia tăng cho Khách hàng, đồng thời nâng cao khả năng phục vụ Khách Hàng bằng những sản phẩm, dịch vụ và công nghệ điện lạnh tiên tiến trên thế giới. Hiện tại, TMD rất tự hào khi đã trở thành nhà phân phối chính thức của các hãng sản xuất thiết bị ngành cơ điện lạnh và công nghệ điện tử nổi tiếng về chất lượng tại Việt Nam và trên thế giới như Cáp điện Z43 - Bộ Quốc Phòng Việt Nam, Ống luồn dây điện HDV - Việt Nam, Cáp điện Benka - Thổ Nhĩ Kỳ, Hệ thống chống sét lan truyền SALTEK- SÉC, Loa thông minh OLLI MAIKA - Việt Nam, cùng nhiều sản phẩm khác…</p>
                    </div>
                </div>
            </div>

            <div className='py-6 lg:py-14 bg-bgSpecial text-center'>
                <div className='container mx-auto'>
                    <h1 className='title'>Lĩnh vực kinh doanh</h1>
                    <div className="flex flex-wrap mt-8 justify-between">
                        <div className="w-1/2 px-4 md:w-1/4 lg:w-1/5 mb-8 hover:text-blue700">
                            <img src={images.Area1} alt='' className='rounded-lg w-full h-[120px] lg:h-[200px] hover:border hover:border-blue500' />
                            <p className='font-bold mt-2 md:mt-4 text-base lg:text-xl'>Dây cáp điện Z43</p>
                        </div>
                        <div className="w-1/2 px-4 md:w-1/4 lg:w-1/5 mb-8 hover:text-blue700">
                            <img src={images.Area2} alt='' className='rounded-lg w-full h-[120px] lg:h-[200px] hover:border hover:border-blue500' />
                            <p className='font-bold mt-2 md:mt-4 text-base lg:text-xl'>Ống luồn dây điện HDV </p>
                        </div>
                        <div className="w-1/2 px-4 md:w-1/4 lg:w-1/5 mb-8 hover:text-blue700">
                            <img src={images.Area3} alt='' className='rounded-lg w-full h-[120px] lg:h-[200px] hover:border hover:border-blue500' />
                            <p className='font-bold mt-2 md:mt-4 text-base lg:text-xl'>Chống sét lan truyền Saltek</p>
                        </div>
                        <div className="w-1/2 px-4 md:w-1/4 lg:w-1/5 mb-8 hover:text-blue700">
                            <img src={images.Area4} alt='' className='rounded-lg w-full h-[120px] lg:h-[200px] hover:border hover:border-blue500' />
                            <p className='font-bold mt-2 md:mt-4 text-base lg:text-xl'>Loa thông minh OLLI MAIKA</p>
                        </div>
                        <div className="w-1/2 px-4 md:w-1/4 lg:w-1/5 mb-8 hover:text-blue700">
                            <img src={images.Area5} alt='' className='rounded-lg w-full  bg-white h-[120px] lg:h-[200px] hover:border hover:border-blue500' />
                            <p className='font-bold mt-2 md:mt-4 text-base lg:text-xl'>Máy lọc nước Famor</p>
                        </div>
                    </div>
                </div>
            </div>

            <Carousel data={dataProduct} isProduct={true} title={'Sản phẩm nổi bật'} slidesToShow={4} centerPadding="50px" />
            <Carousel data={dataPost} title={'Tin tức mới'} bgColor={'bg-bgSpecial'} slidesToShow={3} centerPadding="150px" />
            <Carousel data={arrPartner} isNews={true} title={'Đối tác của chúng tôi'} slidesToShow={4} centerPadding="150px" />

        </main>
    )
}
