import React from 'react';
import { Link } from 'react-router-dom';
import './style.css'

const CardNews = ({ image, title, slug }) => {

    return (
        <Link to={`news/${slug}`} className='cursor-pointer'>
            <div className="!z-5 mx-auto relative rounded-lg bg-white bg-clip-border hover:shadow-hover flex flex-col w-full mb-2 md:mb-8 mt-4 lg:mt-0">
                <div className="h-full w-full mb-2">
                    <div className="relative w-full">
                        <img src={image} className="w-full rounded-t-lg h-[190px] lg:h-[250px] object-cover" alt="" />
                    </div>
                    <p className="text-lg lg:text-xl font-medium text-left p-3 h-[70px] an">{title}</p>
                </div>
            </div>
        </Link>
    );
}

export default CardNews;
