import React from 'react';
import './style.css'

const CardProduct = ({ image, productName, category }) => {

    return (
        <div>
            <div className="!z-5 mx-auto relative rounded-[20px] bg-white hover:bg-blue-50 hover:border hover:border-blue-500 bg-clip-border shadow-hover flex flex-col  w-4/5 md:w-full p-3 md:p-4 lg:mb-6 mt-4 lg:mt-0">
                <div className="h-full w-full">
                    <div className="relative w-full">
                        <img src={image} className="mb-4 lg:mb-8 h-[200px] lg:h-[272px] w-full rounded-lg" alt="" />
                    </div>
                    <div className="mb-1 flex justify-between px-1 flex-col items-start">
                        <div className="text-left h-auto md:h-[90px] lg:h-[120px] flex flex-col justify-between">
                            <div>
                                <p className="lg:text-xs text-[10px] font-medium text-gray-600">
                                    {category}
                                </p>
                                <p className="lg:text-xl an text-base font-bold text-navy-700 lg:mt-2 lg:mb-4 ">
                                    {productName}
                                </p>
                            </div>
                            <div className="flex mt-2 lg:mt-0">
                                <p className="font-bold lg:text-xl text-base text-red500 my-0">
                                    Liên hệ
                                    {/* <span classNameName='text-sm font-thin line-through ml-2 text-grey500'>
                                    1.000.000đ
                                </span> */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardProduct;
