import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css'
import { CaretLeft, CaretRight } from 'phosphor-react';
import CardProduct from './card/CardProduct';
import CardNews from './card/CardNews';


const Carousel = ({ data, title, bgColor, slidesToShow, centerPadding, isNews, isProduct }) => {
    const slider = useRef(null);
    const [showBtn, setShowBtn] = useState(false);

    const settings = {
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: slidesToShow,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    infinite: true,
                    slidesToScroll: 1,
                    slidesToShow: slidesToShow - 1,
                    dots: false,
                    autoplay: true,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    infinite: true,
                    slidesToScroll: 1,
                    slidesToShow: slidesToShow - (isProduct ? 2 : 1),
                    dots: false,
                    autoplay: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    infinite: true,
                    slidesToScroll: 1,
                    slidesToShow: slidesToShow - (isProduct ? 3 : 2),
                    dots: false,
                    autoplay: true,
                }
            },

        ]
    };

    const handlePrev = () => slider.current.slickPrev();
    const handleNext = () => slider.current.slickNext();

    const handleShowButton = (e) => {
        setShowBtn(e)
    }

    return (
        <div className={bgColor} onMouseMove={() => handleShowButton(true)} onMouseOut={() => handleShowButton(false)}>
            <div className='px-4 container mb-0 md:mb-8'>
                <h1 className='title pt-8 lg:pt-16 lg:pb-6'>{title}</h1>
                <div className='relative w-full h-full'>
                    <button className={`absolute left-[-15px] top-1/2 -mt-[44px] hidden ${showBtn && 'lg:block'}`} onClick={handlePrev}>
                        <CaretLeft size={30} className='text-grey500' />
                    </button>
                    <Slider ref={slider} {...settings}>
                        {data?.map(item => (!bgColor && !isNews) ?
                            <CardProduct
                                image={item.image}
                                category={item.category}
                                productName={item.productName}
                            />
                            : isNews ?
                                <img src={item} alt='' className='rounded-2xl w-1/2 p-4 h-[140px] object-contain md:w-1/4' />
                                : item?.avatar && <CardNews
                                    image={item.avatar}
                                    title={item.title}
                                    slug={item.slug}
                                />
                        )}
                    </Slider>
                    <button className={`absolute right-[-15px] top-1/2 -mt-[44px] hidden ${showBtn && 'lg:block' }`} onClick={handleNext}>
                        <CaretRight size={30} className='text-grey500' />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Carousel;
