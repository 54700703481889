
import React from 'react';

import { images } from '../constant/GetImages';
import { Link } from 'react-router-dom';
import { dataPost } from '../common/data';

export default function News() {

    const firstPost = dataPost[0];

    return (
        <main className='container px-4 '>
            <div className='my-8 lg:my-14 '>
                <div className='flex flex-col lg:flex-row justify-between items-start h-auto '>
                    <img src={firstPost.avatar} alt='' height={'100%'} className='w-full lg:w-1/2 rounded' />
                    <div className='px-0 lg:px-8 text-clip text-justify'>
                        <h1 className='title text-blue700 uppercase my-4 md:mb-4 lg:my-0'><Link to={firstPost.slug}>{firstPost.title}</Link></h1>
                        <p className='hidden lg:block text-base lg:text-lg text-ellipsis overflow-hidden text-justify'>Ngày 17/03/2023, buổi lễ ký kết hợp tác trở thành nhà phân phối trên thị trường toàn quốc giữa Công ty Cổ phần Công nghệ OLLI (OLLI Technology) và Công ty TNHH Ứng dụng và phát triển Công nghệ TMD (TMD) đã được diễn ra tại văn phòng của OLLI - 96 NTMK, Phường 6, Quận 3, TP.Hồ Chí Minh.
                            Công ty TNHH TMD được thành lập năm 2021 với nhiệm vụ chuyên cung cấp các thiết bị ngành cơ điện lạnh và mang đến giá trị gia tăng cho Khách hàng, đồng thời nâng cao khả năng phục vụ Khách Hàng bằng những sản phẩm, dịch vụ và công nghệ điện lạnh tiên tiến trên thế giới.
                        </p>
                    </div>
                </div>
                {/* <div className='px-8 container lg:px-8 text-base lg:text-lg text-ellipsis overflow-hidden text-justify'>
                    <p className='my-1'>Hiện tại, TMD rất tự hào khi đã trở thành nhà phân phối chính thức của các hãng sản xuất thiết bị ngành cơ điện lạnh và công nghệ điện tử nổi tiếng về chất lượng tại Việt Nam và trên thế giới như Cáp điện Z43 - Bộ Quốc Phòng Việt Nam, Cáp điện Benka - Thổ Nhĩ Kỳ,...</p>
                    <p className='my-1'>Sự hợp tác giữa OLLI và TMD đã đánh dấu một bước ngoặt lớn trong quá trình phát triển của hai bên, góp phần đưa loa thông minh tích hợp trợ lý ảo MAIKA và các sản phẩm trong hệ sinh thái nhà thông minh như thiết bị ngành cơ điện lạnh đến gần hơn với gia đình Việt trên khắp cả nước, đồng thời mang đến cho người dùng trải nghiệm chất lượng và tiện nghi nhất.</p>
                    <p className='my-1'>Tham gia buổi lễ ký kết có đại diện của Công ty OLLI Technology là Ông Bùi Bách Việt – chức vụ Phó Tổng Giám đốc; về phía Công ty TNHH TMD có Ông Phan Hùng Minh – chức vụ Giám đốc.</p>
                    <p className='my-1'> Phát biểu tại lễ ký kết, Ông Bùi Bách Việt - Phó Tổng Giám đốc của OLLI Technology cho biết: "Chúng tôi đánh giá cao các thành tựu trong lĩnh vực phân phối thiết bị cơ điện lành và công nghệ điện tử mà TMD đã đạt được chỉ trong hơn 1 năm sau khi thành lập. Chúng tôi tin tưởng rằng TMD sẽ phát huy được thế mạnh của cả hai bên và cùng hướng đến một mục tiêu chung là phát triển thị trường loa thông minh và các sản phẩm smarthome trên toàn quốc".</p>
                    <p className='my-1'>Ông Phan Hùng Minh - Giám đốc của TMD chia sẻ tâm huyết: "Mục tiêu của chúng tôi là cung cấp và phân phối ngành cơ điện lạnh tối ưu nhằm phục vụ cho ngành công nghiệp và dân dụng. Bên cạnh đó, chúng tôi cũng tạo được liên kết bền vững với đơn vị thi công M&E chuyên nghiệp nhằm phục vụ cho các công trình trên toàn quốc. Với tinh thần đó, chúng tôi xin cam kết đem lại cho OLLI và Quý Khách hàng sự Uy tín, Chất lượng, Chuyên nghiệp và hiệu quả. Chúng tôi tin rằng với sự hợp tác này, TMD tin rằng OLLI sẽ ngày một tiến xa hơn trên con đường mang sản phẩm trợ lý ảo phục vụ cuộc sống cho hàng triệu gia đình Việt".</p>
                    <p className='my-1'>Sự kiện này đánh dấu một bước ngoặt quan trọng với sự kỳ vọng đôi bên sẽ đạt được các mục tiêu phát triển mạnh mẽ trong thời gian tới. Việc thoả thuận hợp tác nhà phân phối cùng TMD cũng là dấu mốc quan trọng của OLLI Technology trong việc đưa sản phẩm công nghệ trí tuệ nhân tạo “made in Vietnam” đến gần hơn với đông đảo người dùng Việt trên toàn quốc.</p>
                </div> */}
            </div>

            <div className='mt-0 lg:mt-16 mb-8 text-left flex flex-col-reverse lg:flex-row'>
                <div className='w-full lg:w-2/3 mt-8 lg:mt-0'>
                    <h1 className='title mb-6 lg:mb-8'>Tin tức mới</h1>
                    {dataPost.map((item, idx) => idx > 0 && item?.avatar &&
                        <>
                            <div key={idx} className='flex flex-row items-start justify-between h-auto'>
                                <img src={item.avatar} alt='' className='object-cover rounded-lg w-1/3 max-h-[190px] h-[120px] lg:h-full mr-4 md:mr-8' />
                                <div className='w-2/3 text-ellipsis overflow-hidden'>
                                    <h1 className='uppercase mb-4 md:mb-4 text-base lg:text-xl font-bold'>
                                        <Link to={item.slug}>{item.title}</Link>
                                    </h1>
                                    <p className='hidden md:block text-base lg:text-lg text-justify'>
                                        {item.posts[0].content}
                                    </p>
                                </div>
                            </div>
                            <div className='h-[1px] bg-grey100 my-8'></div>
                        </>
                    )}
                </div>
                <div className='block w-full lg:w-1/3 lg:ml-8'>
                    <div className='bg-grey20 px-8 sticky top-0 rounded-lg'>
                        <h1 className='title py-6'>Tin tức nổi bật</h1>
                        <div className='flex flex-col pb-6 lg:pb-0'>
                            <div className="aspect-w-16 aspect-h-9 rounded-lg w-full">
                                <iframe src="https://www.youtube.com/embed/OvWXbwtXmm8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className='w-full'>
                                <h1 className='uppercase text-black text-xl font-bold px-0 my-6'>
                                    <Link to='san-xuat-day-dien-cap-dien-tai-nha-may-Z143'>
                                        Sản xuất dây điện, cáp điện tại Nhà máy Z143, Tổng cục CNQP
                                    </Link>
                                </h1>
                            </div>
                        </div>
                        {/* <p className='text-lg pb-6 text-justify'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis eos fugiat distinctio mollitia minima sint autem nam sunt dolor et? Consectetur dolores fugit magnam eaque obcaecati laudantium eum magni eos. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium facilis ab earum possimus esse aspernatur hic! Quam, praesentium repellat eaque quo, magni ipsam magnam ullam dolor voluptas vel labore temporibus. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi officiis laudantium repellendus ex itaque, nemo commodi id aspernatur iure distinctio est in, saepe quisquam quidem dolorem, ad iste alias praesentium?</p> */}
                    </div>
                </div>
            </div>

        </main>
    )
}
