import Area1 from '../assets/HomePage/Area1.jpg';
import Area2 from '../assets/HomePage/Area2.jpg';
import Area3 from '../assets/HomePage/Area3.jpg';
import Area4 from '../assets/HomePage/Area4.png';
import Area5 from '../assets/HomePage/Area5.png';
import Partner1 from '../assets/HomePage/Partner1.png';
import Partner2 from '../assets/HomePage/Partner2.png';
import Partner3 from '../assets/HomePage/Partner3.png';
import Partner4 from '../assets/HomePage/Partner4.png';
import Partner5 from '../assets/HomePage/Partner5.png';
import Partner6 from '../assets/HomePage/Partner6.jpg';
import Partner7 from '../assets/HomePage/Partner7.png';
import Partner8 from '../assets/HomePage/Partner8.png';
import Partner9 from '../assets/HomePage/Partner9.png';
import Partner10 from '../assets/HomePage/Partner10.jpg';
import Banner from '../assets/HomePage/Banner.svg';
import Product1 from '../assets/HomePage/Product1.jpg';
import Product2 from '../assets/HomePage/Product2.jpg';
import Product3 from '../assets/HomePage/Product3.png';
import Product5 from '../assets/HomePage/Product5.png';
import Product6 from '../assets/HomePage/Product6.png';
import Product7 from '../assets/HomePage/Product7.png';
import Product8 from '../assets/HomePage/Product8.jpg';
import Product9 from '../assets/HomePage/Product9.png';
import Product10 from '../assets/HomePage/Product10.jpg';
import Product11 from '../assets/HomePage/Product11.jpg';
import Product12 from '../assets/HomePage/Product12.png';
import Product13 from '../assets/HomePage/Product13.png';
import Product14 from '../assets/HomePage/Product14.png';
import Product15 from '../assets/HomePage/Product15.png';
import Product16 from '../assets/HomePage/Product16.jpg';
import Product17 from '../assets/HomePage/Product17.jpg';

import NewsPicture1 from '../assets/News/Picture1.png'
import NewsPicture2 from '../assets/News/Picture2.jpg'
import NewsPicture3 from '../assets/News/Picture3.jpg'
import NewsPicture4 from '../assets/News/Picture4.png'
import NewsPicture6 from '../assets/News/Picture6.jpg'
import NewsPicture7 from '../assets/News/Picture7.jpg'

import Post1Picture1 from '../assets/Posts/Post1/Picture1.png'
import Post1Picture2 from '../assets/Posts/Post1/Picture2.png'
import Post2Picture1 from '../assets/Posts/Post2/Picture1.png'
import Post2Picture2 from '../assets/Posts/Post2/Picture2.png'

import Post3Picture1 from '../assets/Posts/Post3/Picture1.jpg'
import Post3Picture2 from '../assets/Posts/Post3/Picture2.jpg'
import Post3Picture3 from '../assets/Posts/Post3/Picture3.png'

import Post4Picture1 from '../assets/Posts/Post4/Picture1.jpg'
import Post4Picture2 from '../assets/Posts/Post4/Picture2.png'
import Post4Picture3 from '../assets/Posts/Post4/Picture3.png'
import Post4Picture4 from '../assets/Posts/Post4/Picture4.jpg'

import Post5Picture1 from '../assets/Posts/Post5/Picture1.jpg'
import Post5Picture2 from '../assets/Posts/Post5/Picture2.png'

export const images = {
    Product17: Product17,
    Product16: Product16,
    Product12: Product12,
    Product13: Product13,
    Product14: Product14,
    Product15: Product15,
    Product11: Product11,
    Product10: Product10,
    Product9: Product9,
    Product8: Product8,
    Product7: Product7,
    Product6: Product6,
    Product5: Product5,
    Product2: Product2,
    Product3: Product3,
    Product1: Product1,
    Banner: Banner,
    Partner10: Partner10,
    Partner9: Partner9,
    Partner8: Partner8,
    Partner7: Partner7,
    Partner6: Partner6,
    Partner1: Partner1,
    Partner2: Partner2,
    Partner3: Partner3,
    Partner4: Partner4,
    Partner5: Partner5,
    Area1: Area1,
    Area2: Area2,
    Area3: Area3,
    Area4: Area4,
    Area5: Area5,

    NewsPicture1: NewsPicture1,
    NewsPicture2: NewsPicture2,
    NewsPicture3: NewsPicture3,
    NewsPicture4: NewsPicture4,
    NewsPicture6: NewsPicture6,
    NewsPicture7: NewsPicture7,

    Post1Picture1: Post1Picture1,
    Post1Picture2: Post1Picture2,
    Post2Picture1: Post2Picture1,
    Post2Picture2: Post2Picture2,
    Post3Picture1: Post3Picture1,
    Post3Picture2: Post3Picture2,
    Post3Picture3: Post3Picture3,
    Post4Picture1: Post4Picture1,
    Post4Picture2: Post4Picture2,
    Post4Picture3: Post4Picture3,
    Post4Picture4: Post4Picture4,
    Post5Picture1: Post5Picture1,
    Post5Picture2: Post5Picture2,
};
export const getImages = (url, w, h, className) => {
    return <img src={url} width={w} height={h} className={className} alt={url} />;
}